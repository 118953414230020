<template>
  <b-list-group class="rounded-0">
    <draggable v-model="data" handler=".draggable-task-handle" @end="handlerSiraChange">
      <transition-group type="transition" name="flip-list">
        <div v-for="item in data" :key="item._id">
          <b-list-group-item
            class="d-flex justify-content-between align-items-center mb-25"
            :class="item.statu ? ' border-primary' : ' border-danger'"
          >
            <div class="d-flex justify-content-start">
              <div class="mr-2 pr-1 border-right">
                <feather-icon icon="MoveIcon" class="cursor-move draggable-task-handle" size="22" />
              </div>
              <h6 class="mt-25">{{ item.baslik }}</h6>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <b-button-group>
                  <b-button
                    squared
                    size="sm"
                    :variant="item.statu ? 'success' : 'danger'"
                    v-b-tooltip.hover.top="item.statu ? 'Aktif' : 'Pasif'"
                  >
                    <feather-icon icon="EyeIcon" v-if="item.statu" />
                    <feather-icon icon="EyeOffIcon" v-else />
                  </b-button>
                  <b-button squared size="sm" variant="warning" v-b-tooltip.hover.top="'Güncelle'" @click="$emit('update', item)">
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button squared size="sm" variant="danger" v-b-tooltip.hover.top="'Sil'" @click="handlerRemove(item.k_no)">
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </b-list-group-item>
        </div>
      </transition-group>
    </draggable>
  </b-list-group>
</template>

<script>
import draggable from 'vuedraggable';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCardNormal,
    draggable,
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  methods: {
    handlerRemove(k_no) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('bolgeSil', k_no).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Kayit silinmiiştir.`,
                },
              });
              this.$emit('refresh', k_no);
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: res.data.message,
                },
              });
            }
          });
        }
      });
    },

    handlerSiraChange() {
      this.$store.dispatch('bolgeSiraGuncelle', this.data).then((res) => {
        if (res.data.success === true) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Silme`,
              icon: 'Trash2Icon',
              variant: 'success',
              text: `Sıra güncellenmiştir.`,
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
